import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

// import "./DejaVuSans-normal.js";
// import "./DejaVuSans-Bold-bold.js";
// import "./roboto-v30-latin-regular-normal.js";
// import "./roboto-v30-latin-700-bold.js";
// import "./OpenSans-Regular-normal.js";
// import "./OpenSans-Bold-bold.js";
import "./SourceSans3-Regular-normal.js";
import "./SourceSans3-Bold-bold.js";

// Create PDF from application form
// $("#form-application-de").on("submit", function(e) {
//    if ($("#bot-field").val()) { return 0; }
//    createFormPDF()
//});
//
// multistep from modified from https://www.w3schools.com/howto/howto_js_form_steps.asp 

var currentTab = 0; // Current tab is set to be the first tab (0)
showTab(currentTab); // Display the current tab

function showTab(n) {
    var x = document.getElementsByClassName("appl-category");

    x[n].style.display = "block";

    // autofocus first input/textarea
    var y = x[n].querySelectorAll("input, textarea");
    if (y.length > 0 && y[0].type != "check" && y[0].type != "radio") {
        y[0].focus();
    }

    // ... and fix the Previous/Next buttons:
    if (n == 0) {
        document.getElementById("prevbtn-de").style.display = "none";
    } else {
        document.getElementById("prevbtn-de").style.display = "inline";
    }
    if (n == (x.length - 1)) {
        showDocuments()
        document.getElementById("nextbtn-de").value = "Formular erstellen";
        // document.getElementById("nextbtn-de").type = "submit";
    } else {
        document.getElementById("nextbtn-de").value = "Weiter";
    }
    // ... and run a function that displays the correct step indicator:
    for (var i = currentTab; i <= n; i++) {
        document.getElementsByClassName("step")[i].className += " finish";
    }
    fixStepIndicator(n)
}

function nextPrev(i) {
    // This function will figure out which tab to display
    var x = document.getElementsByClassName("appl-category");
    // Exit the function if any field in the current tab is invalid:
    if (i == 1 && !validateForm()) return false;
    // Hide the current tab:
    x[currentTab].style.display = "none";
    // Increase or decrease the current tab by 1:
    var n_skip = skipTab(i);

    currentTab = currentTab + i;
    // if you have reached the end of the form... :
    if (currentTab >= x.length) {
        //...the form gets submitted:
        if ($("#bot-field").val()) { return 0; }
        // document.getElementById("form-application-de").submit();
        createFormPDF()
        showTab(currentTab - 1);
        return false;
    }
    currentTab += n_skip;
    // Otherwise, display the correct tab:
    showTab(currentTab);
}

function skipTab(i) {
    // n = 0: personal details
    // n = 1: contact / language
    // n = 2: wage conflict
    // n = 3: lawsuit
    // n = 4: social
    // n = 5: health
    // n = 6: money
    // n = 7: send / confirmation
    if (((currentTab == 4 && i > 0)  || (currentTab == 7 && i < 0)) &&
        ($("#soz-sgb2").is(":checked") || 
            $("#soz-wogg").is(":checked") || 
            $("#soz-sgbxii").is(":checked") ||
            $("#soz-p27a").is(":checked") ||
            $("#soz-p6a").is(":checked"))) {
        return 2 * i;
    } else if (((currentTab == 5 && i > 0)  || (currentTab == 7 && i < 0)) &&
        ($("#soz-sgb2").is(":checked") || 
            $("#soz-wogg").is(":checked") || 
            $("#soz-sgbxii").is(":checked") ||
            $("#soz-p27a").is(":checked") ||
            $("#soz-p6a").is(":checked") ||
            $("#kgs-age").is(":checked") || 
            $("#kgs-h").is(":checked") ||
            $("#kgs-ag").is(":checked") ||
            $("#kgs-b").is(":checked") ||
            $("#kgs-gl").is(":checked") ||
            $("#kgs-sgbxi").is(":checked") ||
            $("#kgs-beh80").is(":checked"))) {
        return i;
    }
    return 0;
}

window.nextPrev = nextPrev;

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};


function validateForm() {
    // This function deals with validation of the form fields
    var x, y, i, j, valid = true;
    x = document.getElementsByClassName("appl-category");
    y = x[currentTab].getElementsByTagName("input");


    // A loop that checks every input field in the current tab:
    for (i = 0; i < y.length; i++) {
        // If a field is empty...
        if (y[i].hasAttribute("required") && ((y[i].type == "email" && !validateEmail(y[i].value)) || (y[i].value == ""))) {
            // add an "invalid" class to the field:
            y[i].className += " invalid";
            // and set the current valid status to false:
            valid = false;
        } else if (y[i].hasAttribute("required") && (y[i].type == "checkbox" && !y[i].checked)) {
            y[i].className += " invalid";
            valid = false;
        }
    }

    const radio_grps = ["sex", "lang", "union", "insur", "pkh", "lawyer", "lawsuit", "whb-1p-income", "whb-np-income", "whb-npers"];
    for (i = 0; i < radio_grps.length; i++) {
        var checked = x[currentTab].querySelectorAll("input[name='"+radio_grps[i]+"']:checked");
        var radio = x[currentTab].querySelectorAll("input[name='"+radio_grps[i]+"']");
        if (radio.length > 0 && radio[0].hasAttribute("required") && checked.length == 0) {
            valid = false;
            for (j = 0; j < radio.length; j++) {
                radio[j].className += " invalid";
                }
        }
    }



    //var radio = x[currentTab].querySelectorAll("input[name='sex']")
   // var radio_checked = x[currentTab].querySelectorAll("input[type='radio']:checked")
   // if (radio.length > 0 && radio_checked.length == 0) {
   //     valid = false;
   //     radio[0].className += " invalid";
   // }

    var text = x[currentTab].getElementsByTagName("textarea");

    // A loop that checks every input field in the current tab:
    for (i = 0; i < text.length; i++) {
        // If a field is empty...
        if (text[i].hasAttribute("required") && text[i].value == "") {
            text[i].className += " invalid";
            valid = false;
        }
    }


    // If the valid status is true, mark the step as finished and valid:
    if (valid) {
        document.getElementsByClassName("step")[currentTab].className += " finish";
    }
    return valid; // return the valid status
}

function fixStepIndicator(n) {
    // This function removes the "active" class of all steps...
    var i, x = document.getElementsByClassName("step");
    for (i = 0; i < x.length; i++) {
        x[i].className = x[i].className.replace(" active", "");
    }
    //... and adds the "active" class to the current step:
    x[n].className += " active";
}


function createFormPDF() {
    var doc = new jsPDF();
    doc.setFont("SourceSans3-Regular");
    // doc.setFont("OpenSans-Regular");
    // doc.setFont("roboto-v30-latin-regular");
    // doc.setFont("DejaVuSans");
    // font = "DejaVuSans";
    // font_bold = "DejaVuSans-Bold";
    // var font = "OpenSans-Regular";
    // var font_bold = "OpenSans-Bold";
    var font = "SourceSans3-Regular";
    var font_bold = "SourceSans3-Bold";

    var padleft = 12;
    var indent = 5;

    var page_count = 1;

    var box_empty = "☐";
    var box_check = "☑";
    var box = "";

    function closure(i) {
        i += 3 * lh;
        if ($("#terms").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        let text_terms = doc.splitTextToSize("Ich habe verstanden, dass dieses Formular nicht gesendet wird. Das resultierende PDF sende ich unterschrieben per E-Mail an info@payday-ev.de. Die übermittelten Daten dienen nur internen Zwecken und werden nur von Mitarbeitenden von Payday e.V. eingesehen.", 180);
        var offset2 = (text_terms.length - 1) * 4;
        doc.text(box, padleft, i);
        doc.text(text_terms, padleft + indent, i); i += 3 * offset2;

        doc.text("       Ort, Datum", 20, i);
        doc.text("             Unterschrift", 120, i);
        i += 1.5 * lh;
        doc.text("________________________________", 20, i);
        doc.text("________________________________", 120, i);
    }

    doc.setFontSize(14).setFont(font_bold, "bold");
    doc.text("Antrag auf Unterstützung durch den Payday e.V. Solidaritätsfonds", padleft, 20);

    var i = 32;
    const lh = 8;
    // doc.setFontSize(14).setFont("roboto-v30-latin-700", "bold");
    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("1. Persönliche Angaben des/der Antragsteller:in", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");

    doc.text(String(page_count), 105, 290);
    page_count += 1;

    doc.text("Name, Vorname:   " + $("#name2").val() + ", " + $("#name1").val(), padleft, i); i += lh;
    // doc.text("Name: " + $("#name2").val(), padleft, i); i += lh;
    doc.text("Anschrift:   " + $("#street").val() + ", " + $("#zip").val() + " " + $("#city").val(), padleft, i); i += lh;
    // doc.text($("#zip").val() + " " + $("#city").val(), 32, i); i += lh;
    let datearr = $("#birthdate").val().split("-");
    doc.text("Geburtsdatum:   " + datearr[2] + "." + datearr[1] + "." + datearr[0], padleft, i); i += lh;
    let boxes = [box_empty, box_empty, box_empty];
    if ($("input[name='sex']:checked").val() == "w") {
        boxes[0] = box_check;
    }
    else if ($("input[name='sex']:checked").val() == "m") {
        boxes[1] = box_check;
    }
    else if ($("input[name='sex']:checked").val() == "d") {
        boxes[2] = box_check;
    }

    doc.text("Geschlecht:   " + boxes[0] + " weiblich  " + boxes[1] + " männlich  " + boxes[2] + " divers", padleft, i); i += lh;
    
    i += lh;
    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("2. Kontakt und Sprache", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");
    doc.text("Telefonnummer:   " + $("#tel").val(), padleft, i); i += lh;
    doc.text("Email:   " + $("#email").val(), padleft, i); i += lh;
    let lang = "Deutsch";
    if ($("input[name='lang']:checked").val() == "en") {
        lang = "Englisch";
    } else if ($("input[name='lang']:checked").val() == "xx") {
        lang = $("#lang-name").val();
    }
        
    doc.text("Kommunikationssprache:   " + lang, padleft, i); i += lh;
    if ($("input[name='lang']:checked").val() == "xx") {
        boxes = [box_empty, box_empty];
        if ($("input[name='lang-transl']:checked").val()) {
            boxes[0] = box_check;
        }
        else {
            boxes[1] = box_check;
        }
        doc.text("Übersetzung ist vorhanden:   " + boxes[0] + " Ja  " + boxes[1] + " Nein", padleft, i); i += lh;
    }

    i += lh;
    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("3. Lohnkonflikt", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");
    doc.text("Angaben zum betrieblichen Engagement:", padleft, i); i += lh;
    let text_engmnt = doc.splitTextToSize($("#engmnt").val(), 180);
    let offset = (text_engmnt.length - 1) * 4;
    doc.text(text_engmnt, padleft + indent, i); i += lh;

    i += offset + lh;

    doc.text("Angaben zum Fall von Lohnentzug:", padleft, i); i += lh;
    let text_case = doc.splitTextToSize($("#case").val(), 180);
    offset = (text_case.length - 1) * 4;
    doc.text(text_case, padleft + indent, i); i += lh;
    i += offset + lh;

    boxes = [box_empty, box_empty];
    if ($("input[name='union']:checked").val() == 0) {
        boxes[0] = box_check;
    }
    else {
        boxes[1] = box_check;
    }
    doc.text("Gewerkschaftmitgliedschaft:", padleft, i); i += lh;
    doc.text(boxes[0] + " Nein  ", padleft + indent, i); i += lh;
    doc.text(boxes[1] + " Ja, in der Gewerkschaft: " + $("#union-name").val(), padleft + indent, i); i += lh;

    i += lh;

    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("4. Lohnklage & 3echtsschutz ", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");

    boxes = [box_empty, box_empty];
    if ($("input[name='lawsuit']:checked").val() == 0) {
        boxes[0] = box_check;
    }
    else {
        boxes[1] = box_check;
    }

    let datestr = "";
    if ($("input[name='lawsuit']:checked").val() == 1) {
        datearr = $("#lawsuit-date").val().split("-");
        datestr = datearr[2] + "." + datearr[1] + "." + datearr[0];
    }

    doc.text("Lohnklage bereits eingereicht:   " + boxes[0] + " Nein  " + boxes[1] + " Ja, am " + datestr, padleft, i); i += lh;

    if ($("input[name='lawsuit']:checked").val() == 1) {
        doc.text("Zukünftige und bereits stattgefundene Gerichtstermine:", padleft, i); i += lh;
        let text_event = doc.splitTextToSize($("#lawsuit-events").val(), 180);
        offset = (text_event.length - 1) * 4;
        doc.text(text_event, padleft + indent, i); i += lh;
    }
    //
    // check for page break
    let page_break1 = 0;
    if (i > 240) {
        // --------------- PAGE 2 ----------------
        page_break1 = 1;
        doc.addPage();
        i = 18;
    }

    doc.text("Klage- bzw. Antragssumme:   " + $("#lawsuit-sum").val() + " €", padleft, i); i += 1.5 * lh;

    boxes = [box_empty, box_empty];
    if ($("input[name='insur']:checked").val() == 0) {
        boxes[0] = box_check;
    }
    else {
        boxes[1] = box_check;
    }
    doc.text("Rechtsschutzversicherung:", padleft, i); i += lh;
    doc.text(boxes[0] + " Nein  ", padleft + indent, i); i += lh;
    doc.text(boxes[1] + " Ja, bei der Versicherung: " + $("#insur-name").val(), padleft + indent, i); i += 1.5 * lh;

    // check for page break
    if (i > 240 && page_break1 == 0) {
        // --------------- PAGE 2 ----------------
        page_break1 = 1;
        doc.addPage();
        i = 18;
    }

    boxes = [box_empty, box_empty];
    if ($("input[name='pkh']:checked").val() == 0) {
        boxes[0] = box_check;
    }
    else {
        boxes[1] = box_check;
    }
    doc.text("Ich beabsichtige, Prozesskostenhilfe zu beantragen:   " + boxes[0] + " Nein  " + boxes[1] + " Ja", padleft, i); i += lh;
    let text_pkh = doc.splitTextToSize($("#pkh-extra").val(), 180);
    offset = (text_pkh.length - 1) * 4;
    if (text_pkh.length > 1) {
        doc.text("Anmerkungen:", padleft, i); i += lh;
        doc.text(text_pkh, padleft + indent, i); i += lh;
        i += offset + lh;
    } else {
        doc.text("Anmerkungen:  —", padleft, i); i += 1.5 * lh;
    }

    boxes = [box_empty, box_empty, box_empty];
    if ($("input[name='lawyer']:checked").val() == 0) {
        boxes[0] = box_check;
    } else if ($("input[name='lawyer']:checked").val() == 2) {
        boxes[1] = box_check;
    } else {
        boxes[2] = box_check;
    }

    doc.text("Vertretung durch Anwält*in/Kanzlei:", padleft, i); i += lh;
    doc.text(boxes[0] + " Nein  ", padleft + indent, i); i += lh;
    doc.text(boxes[1] + " Selbstvertretung  ", padleft + indent, i); i += lh;
    doc.text(boxes[2] + " Ja, Name/Kanzlei: " + $("#insur-name").val(), padleft + indent, i); i += lh;

    
    if (page_break1 == 0) {
        // --------------- PAGE 2 ----------------
        doc.addPage();
        i = 18;
    } else {
        i += lh;
    }

    doc.text(String(page_count), 105, 290);
    page_count += 1;


    let page_break2 = 0;
    // doc.setFontSize(12).setFont(font_bold, "bold");
    // doc.text("6. Lohnklage", padleft, i); i += lh + 2;
    // doc.setFont(font, "normal");
    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("5. Hilfebedürftigkeit", padleft, i); i += lh + 2;
    doc.text("5.1 Wirtschaftliche Hilfebedürftigkeit – Bezug von Sozialleistungen", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");

    if (($("#soz-sgb2").is(":checked") || 
            $("#soz-wogg").is(":checked") || 
            $("#soz-sgbxii").is(":checked") ||
            $("#soz-p27a").is(":checked") ||
        $("#soz-p6a").is(":checked"))) {

        doc.text("Hinweis: Bei Bezug nachstehender Leistungen ist ein Nachweis erforderlich.", padleft, i); i += lh;

        doc.text("Ich beziehe Leistungen nach dem ...", padleft, i); i += lh;
        if ($("#soz-sgb2").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " SGB II (Arbeitslosengeld II)", padleft, i); i += lh;

        if ($("#soz-wogg").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " WoGG (Wohngeld)", padleft, i); i += lh;

        if ($("#soz-sgbxii").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " SGB XII (Hilfe zum Lebensunterhalt, z.B. bei Erwerbsminderungsrente)", padleft, i); i += lh;

        if ($("#soz-p27a").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " § 27a BVG (Ergänzende Hilfe zum Lebensunterhalt für Beschädigte und Hinterbliebene)", padleft, i); i += lh;

        if ($("#soz-p6a").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " § 6a BKGG (Kinderzuschlag)", padleft, i); i += lh;

    } else if ($("#kgs-age").is(":checked") || 
        $("#kgs-h").is(":checked") ||
        $("#kgs-ag").is(":checked") ||
        $("#kgs-b").is(":checked") ||
        $("#kgs-gl").is(":checked") ||
        $("#kgs-sgbxi").is(":checked") ||
        $("#kgs-beh80").is(":checked")) {
        
        doc.text(" — trifft nicht zu —", padleft, i); i += 2 * lh;

        // HIDE THIS SECTION IF ANY OF THE PREVIOUS SECTION WAS CHECKED !
        // WE DIDN'T ASK AND DON'T KNOW IF THIS APPLIES


        doc.setFontSize(12).setFont(font_bold, "bold");
        doc.text("5.2 Körperliche, geistige oder seelische Hilfebedürftigkeit", padleft, i); i += lh + 2;
        doc.setFont(font, "normal");
        doc.text("Hinweis: Ein Nachweis ist zwingend erforderlich!", padleft, i); i += lh;

        if ($("#kgs-age").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Ich habe das 75. Lebensjahr vollendet", padleft, i); i += lh;

        if ($("#kgs-h").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Merkzeichen „Hilflos“ (H)", padleft, i); i += lh;

        if ($("#kgs-ag").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Merkzeichen „Außergewöhnliche Gehbehinderung“ (aG)", padleft, i); i += lh;

        if ($("#kgs-b").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Merkzeichen „Berechtigung für ständige Begleitung“ (B)", padleft, i); i += lh;

        if ($("#kgs-gl").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Merkzeichen „Gehörlosigkeit“ (Gl)", padleft, i); i += lh;

        if ($("#kgs-sgbxi").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Anerkennung eines Pflegegrades im Sinne des SGB XI", padleft, i); i += lh;

        if ($("#kgs-beh80").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Grad der Behinderung mindestens 80", padleft, i); i += lh;

    } else {

        doc.text(" — trifft nicht zu —", padleft, i); i += 2 * lh;

        doc.setFontSize(12).setFont(font_bold, "bold");
        doc.text("5.2 Körperliche, geistige oder seelische Hilfebedürftigkeit", padleft, i); i += lh + 2;
        doc.setFont(font, "normal");
        doc.text(" — trifft nicht zu —", padleft, i); i += 2 * lh;


        // --------------- PAGE 3 ----------------
        doc.setFontSize(12).setFont(font_bold, "bold");
        doc.text("5.3 Wirtschaftliche Hilfebedürftigkeit", padleft, i); i += lh + 2;
        doc.setFont(font, "normal");

        // let npers = $("#whb-npers").val();
        let box2 = box_empty;
        if ($("input[name='whb-npers']:checked").val() == 0) {
            box = box_check;
        } else {
            box = box_empty;
            box2 = box_check;
        }
        doc.text("Anzahl der Personen im Haushalt:", padleft, i); i += lh;
        doc.text(box + " eine Person", padleft + indent, i); i += lh;
        doc.text(box2 + " mehrere Personen", padleft + indent, i); i += lh;

        if ($("input[name='whb-npers']:checked").val() == 0) {
            doc.text("Meine Einkünfte* sind – abzüglich eventueller Unterhaltszahlungen –", padleft, i); i += lh;
            let box2 = box_empty;
            if ($("input[name='whb-1p-income']:checked").val() == 0) {
                box = box_check;
            } else {
                box = box_empty;
                box2 = box_check;
            }
            doc.text(box + " nicht höher als 2.815 € brutto", padleft + indent, i); i += lh;
            doc.text(box2 + " höher als 2.815 € brutto", padleft + indent, i); i += lh;
        } else {
            if (i > 220) {
                page_break2 = 1;
                doc.addPage();
                doc.text(String(page_count), 105, 290);
                page_count += 1;
                i = 18;
            }

            doc.text("Mehrpersonenhaushalt: Berechnung des Grenzbetrags", padleft, i); i += lh;

            autoTable(doc, { 
                theme: "plain",
                startY: i,
                head: [["Haushaltsangehörige", "Grenzbetrag", "Anzahl Personen", "Summe"]],
                body: [
                    ["volljährige Ehe-/Lebenspartner in Bedarfsgem.", "2024 €", $("#whb-np-part").val(), 2024 * $("#whb-np-part").val() + " €"],
                    ["volljährige/r Alleinerziehende/r", "2815 €", $("#whb-np-sgl").val(), 2815 * $("#whb-np-sgl").val() + " €"],
                    ["Haushaltsangehörige bis vollendetes 6. Lebensjahr", "1428 €", $("#whb-np-6").val(), 1428 * $("#whb-np-6").val() + " €"],
                    ["Haushaltsangehörige bis vollendetes 14. Lebensjahr", "1560 €", $("#whb-np-14").val(), 1560 * $("#whb-np-14").val() + " €"],
                    ["Haushaltsangehörige bis vollendetes 18. Lebensjahr", "1884 €", $("#whb-np-18").val(), 1884 * $("#whb-np-18").val() + " €"],
                    ["Haushaltsangehörige ab vollendetem 18. Lebensjahr", "1804 €", $("#whb-np-ad").val(), 1804 * $("#whb-np-ad").val() + " €"],
                ],
            });
            i += 60;

            // check for page break
            if (i > 240 && page_break2 == 0) {
                page_break2 = 1;
                doc.addPage();
                doc.text(String(page_count), 105, 290);
                page_count += 1;
                i = 18;
            } else {
                i += 2;
            }

            let limit = calcHouseholdLimit();
            doc.text("Der Brutto-Grenzbetrag des Haushalts beträgt:   " + limit + " €", padleft, i); i += 1.5 * lh;
            doc.text("Unsere Einkünfte* sind – abzüglich eventueller Unterhaltszahlungen –", padleft, i); i += lh;
            let box2 = box_empty;
            if ($("input[name='whb-np-income']:checked").val() == 0) {
                box = box_check;
            } else {
                box = box_empty;
                box2 = box_check;
            }
            doc.text(box + " nicht höher als der berechnete Brutto-Grenzbetrag von " + limit + " €", padleft + indent, i); i += lh;
            doc.text(box2 + " höher als der berechnete Brutto-Grenzbetrag von " + limit + " €", padleft + indent, i); i += lh;

        }

        let text_income = doc.splitTextToSize("*Einkünfte sind insbesondere: Renten in voller Höhe; Zinsen, Dividenden, sonstige Einkünfte aus Kapitalvermögen in voller Höhe; Mieteinnahmen, Pachten u.ä.; Lohn- und Gehaltsbezüge; Unternehmensgewinne; ausländische Einkünfte; alle weiteren Einkünfte im Sinne des Einkommenssteuergesetzes; Wohngeld; Kindergeld; Unterhaltsbezüge und Unterhaltsansprüche", 200);
        offset = (text_income.length - 1) * 4

        i += 2;
        doc.setFontSize(9);
        doc.text(text_income, padleft + indent, i); i += lh;
        doc.setFontSize(12);

        // check for page break
        if (i > 240 && page_break2 == 0) {
            page_break2 = 1;
            doc.addPage();
            doc.text(String(page_count), 105, 290);
            page_count += 1;
            i = 18;
        } else {
            i += offset;
        }

        doc.text("Hinweis: Handelt es sich um Einkommen aus Renten, Pensionen, Versorgungsbezüge usw.,", padleft, i); i += 0.7*lh;
        doc.text("sind Nachweise zwingend beizufügen!", padleft, i); i += 1.5 * lh;


        if ($("#whb-assets").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Ich verfüge nicht über Vermögen mit einem Verkaufswert von über 15.500 €", padleft, i); i += lh;
        let text_assets = doc.splitTextToSize("(Hierzu zählen nicht: Erinnerungsstücke, Hausrat, selbstbewohntes Haus oder Eigentumswohnung, Rücklage für angemessene Altersversorgung) bzw. mein Vermögen kann nicht für den laufenden Unterhalt verwendet werden.)", 180);
        offset = (text_income.length - 1) * 4
        doc.text(text_assets, padleft + indent, i); i += 0.5 * lh + offset;

        // check for page break
        if (i > 240 && page_break2 == 0) {
            // --------------- PAGE 2 ----------------
            page_break2 = 1;
            doc.addPage();
            i = 18;
            doc.text(String(page_count), 105, 290);
            page_count += 1;
        } else {
            i += 4;
        }

        if ($("#whb-hardship").is(":checked")) {
            box = box_check;
        } else {
            box = box_empty;
        }
        doc.text(box + " Bei mir liegt eine wirtschaftliche Notlage vor, durch:", padleft, i); i += lh;
        let text_hardship = doc.splitTextToSize($("#whb-hardship-details").val(), 180);
        offset = (text_hardship.length - 1) * 4
        doc.text(text_hardship, padleft + indent, i); i += 1.5*lh + offset;
    }

    if (i > 240 && page_break2 == 0) {
        // --------------- PAGE 2 ----------------
        page_break2 = 1;
        doc.addPage();
        i = 18;
        doc.text(String(page_count), 105, 290);
        page_count += 1;
    }

    closure(i);



    doc.addPage()
    doc.text(String(page_count), 105, 290);
    page_count += 1;
    i = 18;

    doc.setFontSize(12).setFont(font_bold, "bold");
    doc.text("Anhang: benötigte Unterlagen", padleft, i); i += lh + 2;
    doc.setFont(font, "normal");

    doc.text("Checkliste der mit dem Antrag einzureichenden Unterlagen:", padleft, i); i += 1.5 * lh;
    doc.text("☐ Kopie deines Arbeitsvertrags", padleft, i); i += lh;

    box = box_empty;
    if ($("input[name='lawsuit']:checked").val()) {
        doc.text(box + " Kopie der Lohnklage", padleft, i); i += lh;
    } else {
        doc.text(box + " Kopien der 3 letzten Lohnabrechnungen", padleft, i); i += lh;
    }

    if ($("#soz-sgb2").is(":checked")) {
        doc.text(box + " Nachweis SGB II (Arbeitslosengeld II)", padleft, i); i += lh;
    }
    if ($("#soz-wogg").is(":checked")) {
        doc.text(box + " Nachweis WoGG (Wohngeld)", padleft, i); i += lh;
    }
    if ($("#soz-sgbxii").is(":checked")) {
        doc.text(box + " Nachweis SGB XII (Hilfe zum Lebensunterhalt, z.B. bei Erwerbsminderungsrente)", padleft, i); i += lh;
    }
    if ($("#soz-p27a").is(":checked")) {
        doc.text(box + " Nachweis § 27a BVG (Ergänzende Hilfe zum Lebensunterhalt für Beschädigte und", padleft, i); i += 0.75 * lh;
        doc.text("Hinterbliebene)", padleft + indent, i); i += lh;
    }
    if ($("#soz-p6a").is(":checked")) {
    doc.text(box + " Nachweis § 6a BKGG (Kinderzuschlag)", padleft, i); i += lh;
    }
    if ($("#kgs-age").is(":checked")) {
        doc.text(box + " Nachweis über Vollendung des 75. Lebensjahres (z.B. Ausweis)", padleft, i); i += lh;
    }
    
    if ($("#kgs-h").is(":checked")) {
        doc.text(box + " Nachweis Merkzeichen „Hilflos“ (H)", padleft, i); i += lh;
    }
    
    if ($("#kgs-ag").is(":checked")) {
        doc.text(box + " Nachweis Merkzeichen „Außergewöhnliche Gehbehinderung“ (aG)", padleft, i); i += lh;
    }
    
    if ($("#kgs-b").is(":checked")) {
        doc.text(box + " Nachweis Merkzeichen „Berechtigung für ständige Begleitung“ (B)", padleft, i); i += lh;
    }
    
    if ($("#kgs-gl").is(":checked")) {
        doc.text(box + " Nachweis Merkzeichen „Gehörlosigkeit“ (Gl)", padleft, i); i += lh;
    }

    if ($("#kgs-sgbxi").is(":checked")) {
        doc.text(box + " Nachweis Anerkennung eines Pflegegrades im Sinne des SGB XI", padleft, i); i += lh;
    }

    if ($("#kgs-beh80").is(":checked")) {
        doc.text(box + " Nachweis Grad der Behinderung mindestens 80", padleft, i); i += lh;
    }


    // doc.save("output.pdf");
    // window.open(URL.createObjectURL(doc.output("blob")))
    doc.output('dataurlnewwindow');
}

// interactive form elements
// FIXME test if  suffix can be removed !
//
// other language, translator
$("#lang-other").click(function() {
    $("#lang-name").prop("disabled", false);
    $("#lang-transl").prop("disabled", false);
    $("#lang-name").prop("required", true);
    $("#appl-checkbox-translator").show();
});
$("#lang-de").click(function() {
    $("#lang-name").prop("disabled", true);
    $("#lang-name").val("");
    $("#lang-name").prop("required", false);
    $("#lang-transl").prop("disabled", true);
    $("#lang-transl").prop("checked", false);
    $("#appl-checkbox-translator").hide();
});
$("#lang-en").click(function() {
    $("#lang-name").prop("disabled", true);
    $("#lang-name").val("");
    $("#lang-name").prop("required", false);
    $("#lang-transl").prop("checked", false);
    $("#lang-transl").prop("disabled", true);
    $("#appl-checkbox-translator").hide();
});

// worker's union
$("#union-yes").click(function() {
    $("#union-name").prop("disabled", false);
    $("#union-name").prop("required", true);
});
$("#union-no").click(function() {
    $("#union-name").prop("disabled", true);
    $("#union-name").prop("required", false);
});

// legal cost insurance
$("#insur-yes").click(function() {
    $("#insur-name").prop("disabled", false);
    $("#insur-name").prop("required", true);
});
$("#insur-no").click(function() {
    $("#insur-name").prop("disabled", true);
    $("#insur-name").prop("required", false);
});

// lawyer
$("#lawyer-yes").click(function() {
    $("#lawyer-name").prop("disabled", false);
    $("#lawyer-name").prop("required", true);
});
$("#lawyer-no").click(function() {
    $("#lawyer-name").prop("disabled", true);
    $("#lawyer-name").prop("required", false);
});
$("#lawyer-self").click(function() {
    $("#lawyer-name").prop("disabled", true);
    $("#lawyer-name").prop("required", false);
});

// lawsuit
$("#lawsuit-yes").click(function() {
    $("#lawsuit-date").prop("disabled", false);
    $("#lawsuit-date").prop("required", true);
    $("#p-lawsuit-events").show();
    $("#lawsuit-events").prop("disabled", false);
    // $("#p-lawsuit-sum").show();
    // $("#lawsuit-sum").prop("disabled", false);
    // $("#lawsuit-sum").prop("required", true);

});
$("#lawsuit-no").click(function() {
    $("#lawsuit-date").prop("disabled", true);
    $("#lawsuit-date").prop("required", false);
    $("#p-lawsuit-events").hide();
    $("#lawsuit-events").prop("disabled", true);
    // $("#p-lawsuit-sum").hide();
    // $("#lawsuit-sum").prop("disabled", true);
    // $("#lawsuit-sum").prop("required", false);
});

//income
$("#whb-1p").click(function() {
    $("#whb-1p-income").show();
    $("#whb-1p-income-more").prop("disabled", false);
    $("#whb-1p-income-more").prop("required", true);
    $("#whb-1p-income-less").prop("disabled", false);
    $("#whb-1p-income-less").prop("required", true);

    $("#whb-np-income").hide();
    $("#whb-np-part").prop("disabled", true);
    $("#whb-np-part").prop("required", false);
    $("#whb-np-sgl").prop("disabled", true);
    $("#whb-np-sgl").prop("required", false);
    $("#whb-np-6").prop("disabled", true);
    $("#whb-np-6").prop("required", false);
    $("#whb-np-14").prop("disabled", true);
    $("#whb-np-14").prop("required", false);
    $("#whb-np-18").prop("disabled", true);
    $("#whb-np-18").prop("required", false);
    $("#whb-np-ad").prop("disabled", true);
    $("#whb-np-ad").prop("required", false);

    $("#whb-np-income2").hide();
    $("#whb-np-income-more").prop("disabled", true);
    $("#whb-np-income-more").prop("required", false);
    $("#whb-np-income-less").prop("disabled", true);
    $("#whb-np-income-less").prop("required", false);
});
$("#whb-np").click(function() {
    $("#whb-1p-income").hide();
    $("#whb-1p-income-more").prop("disabled", true);
    $("#whb-1p-income-more").prop("required", false);
    $("#whb-1p-income-less").prop("disabled", true);
    $("#whb-1p-income-less").prop("required", false);

    $("#whb-np-income").show();
    $("#whb-np-part").prop("disabled", false);
    $("#whb-np-part").prop("required", true);
    $("#whb-np-sgl").prop("disabled", false);
    $("#whb-np-sgl").prop("required", true);
    $("#whb-np-6").prop("disabled", false);
    $("#whb-np-6").prop("required", true);
    $("#whb-np-14").prop("disabled", false);
    $("#whb-np-14").prop("required", true);
    $("#whb-np-18").prop("disabled", false);
    $("#whb-np-18").prop("required", true);
    $("#whb-np-ad").prop("disabled", false);
    $("#whb-np-ad").prop("required", true);

    $("#whb-np-income2").show();
    $("#whb-np-income-more").prop("disabled", false);
    $("#whb-np-income-more").prop("required", true);
    $("#whb-np-income-less").prop("disabled", false);
    $("#whb-np-income-less").prop("required", true);
});

function calcHouseholdLimit() {
    let limit = $("#whb-np-part").val() * 2024 + $("#whb-np-sgl").val() * 2815 + $("#whb-np-6").val() * 1428 + $("#whb-np-14").val() * 1560 + $("#whb-np-18").val() * 1884 + $("#whb-np-ad").val() * 1804;
    return limit;
}
$("#whb-np-part,#whb-np-sgl,#whb-np-6,#whb-np-14,#whb-np-18,#whb-np-ad").on("input", function(e) {
    // console.log(limit);
    let limit = calcHouseholdLimit();
    $("#income-limit").text(limit + " €");
    $("#income-limit2").text(limit + " €");
    $("#income-limit3").text(limit + " €");
});

$("#whb-hardship").click(function() {
    if ($("#whb-hardship").is(":checked")) {
        $("#whb-hardship-details").prop("disabled", false);
        $("#whb-hardship-details").prop("required", true);
    } else {
        $("#whb-hardship-details").prop("disabled", true);
        $("#whb-hardship-details").prop("required", false);
    }
});



function showDocuments() {

    if ($("input[name='lawsuit']:checked").val() == 0) {
        $("#li-lawsuit").hide();
        $("#li-salary").show();
    } else {
        $("#li-lawsuit").show();
        $("#li-salary").hide();
    }
    if ($("#soz-sgb2").is(":checked")) {
        $("#li-sgb2").show();
    } else {
        $("#li-sgb2").hide();
    }
    if ($("#soz-wogg").is(":checked")) {
        $("#li-wogg").show();
    } else {
        $("#li-wogg").hide();
    }
    if ($("#soz-sgbxii").is(":checked")) {
        $("#li-sgbxii").show();
    } else {
        $("#li-sgbxii").hide();
    }
    if ($("#soz-p27a").is(":checked")) {
        $("#li-p27a").show();
    } else {
        $("#li-p27a").hide();
    }
    if ($("#soz-p6a").is(":checked")) {
        $("#li-p6a").show();
    } else {
        $("#li-p6a").hide();
    }
    if ($("#kgs-age").is(":checked")) {
        $("#li-age").show();
    } else {
        $("#li-age").hide();
    }
    if ($("#kgs-h").is(":checked")) {
        $("#li-h").show();
    } else {
        $("#li-h").hide();
    }
    if ($("#kgs-ag").is(":checked")) {
        $("#li-ag").show();
    } else {
        $("#li-ag").hide();
    }
    if ($("#kgs-b").is(":checked")) {
        $("#li-b").show();
    } else {
        $("#li-b").hide();
    }
    if ($("#kgs-gl").is(":checked")) {
        $("#li-gl").show();
    } else {
        $("#li-gl").hide();
    }
    if ($("#kgs-sgbxi").is(":checked")) {
        $("#li-sgbxi").show();
    } else {
        $("#li-sgbxi").hide();
    }
    if ($("#kgs-beh80").is(":checked")) {
        $("#li-beh80").show();
    } else {
        $("#li-beh80").hide();
    }
}
